// Async import lets webpack runtime interface with any remotes
Promise.all([import('./app/App'), import('./bootstrap')])
  .then(([{ App }, { bootStrap }]) =>
    bootStrap({
      app: App,
      elementRoot: document.getElementById('root'),
    })
  )
  // eslint-disable-next-line no-console
  .catch(console.error);
